import React, { FC, useEffect, useRef } from 'react';
import { audiLightTheme, audiDarkTheme, ThemeProvider, useSpacing } from '@audi/audi-ui-react';
import { EditorialColumnsProps } from '../../types';
import { useInView } from '../hooks/useInView';
import { LegalInfo } from './LegalInfo';
import { LegalInfoContainer, EditorialColumnsContainer } from './EditorialColumns.style';
import { TextMode } from './TextMode';
import { NumbersMode } from './NumbersMode';
import { ButtonLabels, LinkElement, useTracking } from '../hooks/useTracking';
import { FA_LOCATOR_ID } from '../utils/utils';

export const EditorialColumns: FC<EditorialColumnsProps> = (props) => {
  const { theme, headingOption, caeIdentifier, disclaimers, components, type, textAlignment } =
    props;

  const numbersContainerSpacing = useSpacing({ spaceStackStart: 'xxxl', spaceStackEnd: 'xxxl' });
  const hasLegalInfo = !!caeIdentifier || !!disclaimers?.length;

  const animationRef = useRef(null);
  const ref = useRef(null);

  const inView = useInView(animationRef, {
    threshold: 0.3,
    stopOnceVisible: true,
  });

  const tracking = useTracking(
    {
      implementer: 2,
    },
    ref,
  );

  useEffect(() => {
    tracking.sendReadyEvent();
    const getTrackingCtaInfo = () => {
      if (type === 'numbers') return;
      const labels = components.flat().filter((column) => column.cta && column.cta.label);
      const buttonLabelList: ButtonLabels | null = labels.length
        ? labels.map((item, index) => ({
            number: index + 1,
            linkElement:
              item.cta?.variant === 'secondary' ? ('cta' as LinkElement) : ('link' as LinkElement),
            label: item.cta?.label || '',
          }))
        : null;
      tracking.registerImpressionTracking(headingOption?.heading, buttonLabelList);
    };
    getTrackingCtaInfo();
  }, []);

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <EditorialColumnsContainer
        data-test-id={FA_LOCATOR_ID}
        innerSpacing={numbersContainerSpacing}
        ref={ref}
      >
        {type === 'text' && (
          <TextMode
            animationRef={animationRef}
            components={components}
            headingOption={headingOption}
            textAlignment={textAlignment}
            inView={inView}
          />
        )}
        {type === 'numbers' && (
          <NumbersMode
            animationRef={animationRef}
            components={components}
            headingOption={headingOption}
            inView={inView}
          />
        )}

        {hasLegalInfo && (
          <LegalInfoContainer data-testid="editorial-colmuns-legal-info-container">
            <LegalInfo consumptionAndEmissions={caeIdentifier} disclaimers={disclaimers} />
          </LegalInfoContainer>
        )}
      </EditorialColumnsContainer>
    </ThemeProvider>
  );
};
