/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { List, ListItem, ListProps, ListItemProps, Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import parse from 'html-react-parser';
import React, { Children, FC, ReactNode, cloneElement } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

enum AllowedTag {
  B = 'b',
  I = 'i',
  U = 'u',
  UL = 'ul',
  LI = 'li',
  P = 'p',
  A = 'a',
  STRONG = 'strong',
}

const transform = (node: ReactNode) => {
  if (typeof node === 'string') {
    return renderTextWithFootnotesReferences(node);
  }
  return node;
};

export function renderReactNodeWithFootnotes(nodes: ReactNode[]) {
  return Children.map(nodes, transform);
}

const StyledList = styled(List as FC<ListProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
      margin-block-end: var(${theme.responsive?.spacing.m});
    `}
`;

const StyledListItem = styled(ListItem as FC<ListItemProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
      font-size: var(${theme.responsive?.typography.copy1.fontSize});
      margin-block-end: var(${theme.responsive?.spacing.s});
      margin-block-start: 0px;
    `}
`;

const createElement = (type: string, props?: object, ...children: ReactNode[]): JSX.Element => {
  const renderChildren = () => renderReactNodeWithFootnotes(children);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (type === AllowedTag.UL) {
    return React.createElement(StyledList, props, renderChildren());
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (type === AllowedTag.LI) {
    return React.createElement(StyledListItem, props, renderChildren());
  }
  return React.createElement(type, props, renderChildren());
};

export function convertRichText(htmlString: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return parse(
    sanitizeHtml(htmlString, {
      allowedTags: Object.values(AllowedTag),
    }),
    {
      library: {
        cloneElement,
        createElement,
        isValidElement: (element: unknown): boolean => {
          return Boolean(element);
        },
      },
    },
  );
}
