import React, { FC } from 'react';
import { Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { HeadingOption, NumbersItem } from '../../types';
import { NumbersHeadlineContainer } from './EditorialColumns.style';
import {
  ComponentContainer,
  ComponentsContainer,
  NumberAndHeadline,
  Row,
} from './NumbersMode.style';

export interface NumbersModeProps {
  animationRef: React.MutableRefObject<null>;
  components: NumbersItem[][];
  inView: boolean;
  headingOption?: HeadingOption;
}

export const NumbersMode: FC<NumbersModeProps> = ({
  animationRef,
  components,
  inView,
  headingOption,
}) => {
  return (
    <>
      {headingOption && (
        <NumbersHeadlineContainer data-testid="text-headline-container">
          <Text
            data-testid="numbers-headline"
            variant="order2"
            as={headingOption.headingTag || 'h2'}
          >
            {renderTextWithFootnotesReferences(headingOption.heading)}
          </Text>
        </NumbersHeadlineContainer>
      )}
      <ComponentsContainer data-testid="numbers-components-container" ref={animationRef}>
        {components.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row data-testid="numbers-components-row" key={`row-${i}`}>
            {row.map((x, j) => (
              <ComponentContainer
                key={`ge-${x.number}`}
                data-testid="numbers-component"
                delay={`${j * 0.3}s`}
                inView={inView}
              >
                {x.title && (
                  <Text key={`ge-title-${x.number}`} as="strong">
                    {renderTextWithFootnotesReferences(x.title)}
                  </Text>
                )}
                <NumberAndHeadline key={`ge-number-and-headline-${x.number}`}>
                  <Text
                    data-testid="numbers-component-number"
                    key={`ge-number-${x.number}`}
                    variant="order1"
                    weight="bold"
                  >
                    {renderTextWithFootnotesReferences(x.number)}
                  </Text>
                  <Text key={`ge-unit-${x.number}`} variant="copy1" spaceInlineStart="xs">
                    {renderTextWithFootnotesReferences(x.unit as string)}
                  </Text>
                </NumberAndHeadline>
                {x.description && (
                  <Text key={`ge-description-${x.number}`} variant="copy2">
                    {renderTextWithFootnotesReferences(x.description)}
                  </Text>
                )}
              </ComponentContainer>
            ))}
          </Row>
        ))}
      </ComponentsContainer>
    </>
  );
};
