import React, { FC, useCallback, useContext } from 'react';
import { Text, Button } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { TextHeadlineContainer } from './EditorialColumns.style';
import { HeadingOption, TextItem } from '../../types';
import { TextComponentContainer, TextComponentsContainer, TextRow } from './TextMode.style';
import RichText from './RichText';
import { useTracking } from '../hooks/useTracking';
import { Context } from '../Context';
import { setHeadlessUrl } from '../utils/setHeadlessUrl';

export interface TextModeProps {
  animationRef: React.MutableRefObject<null>;
  components: TextItem[][];
  inView: boolean;
  headingOption?: HeadingOption;
  textAlignment: 'left' | 'center';
}

type LargePaddingElement = {
  length: number;
  rowIndex: number;
};

// in the Text mode, the padding on the element will be used XL for 3 & 6 elements.
// XXXL for 2, 4 and 5(only 1st row) elements
export const largePaddingElements: LargePaddingElement[] = [
  { length: 2, rowIndex: 0 },
  { length: 4, rowIndex: 0 },
  { length: 4, rowIndex: 0 },
  { length: 5, rowIndex: 0 },
];

export const TextMode: FC<TextModeProps> = ({
  animationRef,
  components,
  inView,
  headingOption,
  textAlignment,
}) => {
  const tracking = useTracking({
    implementer: 2,
  });
  const { layerManager } = useContext(Context);
  const IsLargePaddingElement = useCallback((object: LargePaddingElement): boolean => {
    return Boolean(largePaddingElements.find((element) => element === object));
  }, []);

  const handleOpenInLayer = useCallback((url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      },
    );
  }, []);

  return (
    <>
      {headingOption && (
        <TextHeadlineContainer data-testid="numbers-headline-container">
          <Text
            data-testid="numbers-headline"
            variant="order2"
            as={headingOption.headingTag || 'h2'}
          >
            {renderTextWithFootnotesReferences(headingOption.heading)}
          </Text>
        </TextHeadlineContainer>
      )}
      <TextComponentsContainer data-testid="numbers-components-container" ref={animationRef}>
        {components.map((row, rowIdx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TextRow data-testid="numbers-components-row" key={`row-${rowIdx}`}>
            {row.map((x: TextItem, elementIndex) => (
              <TextComponentContainer
                data-testid="text-component"
                key={`ge-${x.title}`}
                delay={`${elementIndex * 0.3}s`}
                inView={inView}
                textAlignment={textAlignment}
                isLargePadding={IsLargePaddingElement({
                  length: components.length,
                  rowIndex: rowIdx,
                })}
              >
                {x.title && (
                  <Text
                    data-testid="text-component-title"
                    key={`ge-title-${x.title}`}
                    variant="order3"
                    spaceStackEnd="m"
                  >
                    {renderTextWithFootnotesReferences(x.title)}
                  </Text>
                )}
                {x.description && <RichText text={x.description} />}
                {x.cta && x.cta.label && x.cta.url && (
                  <Button
                    newWindow={x.cta.openInTabOrLayer === 'tab'}
                    stretch
                    variant={x.cta.variant}
                    href={x.cta.openInTabOrLayer === 'layer' ? undefined : x.cta.url}
                    onClick={() => {
                      if (x.cta?.openInTabOrLayer === 'layer') {
                        handleOpenInLayer(x.cta.url);
                      }
                      return tracking.sendClickEvent(
                        x.cta!.label,
                        x.cta!.url,
                        x.cta!.variant === 'text' ? 'text link' : 'cta',
                        x.cta!.variant === 'text' ? 'text link' : 'button',
                        elementIndex + 1,
                      );
                    }}
                    size="medium"
                    data-testid="text-component-button"
                    spaceStackStart="l"
                  >
                    {x.cta.label}
                  </Button>
                )}
              </TextComponentContainer>
            ))}
          </TextRow>
        ))}
      </TextComponentsContainer>
    </>
  );
};
