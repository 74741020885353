import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { Theme } from '@audi/audi-ui-react';

import { Context } from '../Context';
import { Disclaimers } from './Disclaimers';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';

export interface StyledLegalWWrapperProps {
  isLandscapeSize?: boolean;
}

const StyledLegalInfo = styled.div<StyledLegalWWrapperProps>`
  sup {
    // footnotes too small fix
    font-size: 16px !important;
    vertical-align: initial;
  }

  ${({ theme }: { theme: Theme }) => {
    return `
      margin-top: var(${theme.responsive?.spacing.m});
      margin-bottom: var(${theme.responsive?.spacing.m});
    `;
  }}
`;

export interface LegalInfoProps {
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
}

export const LegalInfo: React.FC<LegalInfoProps> = ({ consumptionAndEmissions, disclaimers }) => {
  const { localeService, vueFormatterService } = useContext(Context);
  const hasContent = consumptionAndEmissions || (disclaimers && disclaimers.length);

  // eslint-disable-next-line no-console
  console.log(localeService);
  // eslint-disable-next-line no-console
  console.log(vueFormatterService);

  if (!hasContent) return null;

  return (
    <StyledLegalInfo className="legal-info" data-testid="legal-info-section">
      {vueFormatterService && localeService && (
        <ConsumptionAndEmissions
          caeIds={consumptionAndEmissions}
          vueFormatterService={vueFormatterService}
          localeService={localeService}
        />
      )}
      <Disclaimers disclaimers={disclaimers} />
    </StyledLegalInfo>
  );
};

LegalInfo.displayName = 'LegalInfo';
